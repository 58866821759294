<template>
  <div class="page">
    <b-card>
      <div
        class="custom-search d-flex"
        style="justify-content:space-between"
      >
        <b-button
          variant="outline-primary"
          class="mb-2"
          @click="$router.push({name: 'Create Office'})"
        >
          <feather-icon icon="PlusIcon" />
          Add Office
        </b-button>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <b-table
        responsive
        :items="offices"
        :fields="fields"
        :filter="searchTerm"
      >
        <!-- <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
          </b-form-checkbox>
        </template> -->
        <!--
        <template #row-details="row">
          <b-card>
            <validation-observer
              ref="registerForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-register-form mt-2"
              >
                <b-row class="mb-2">
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <b-form-group
                      label="Short Name"
                      label-for="short-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Short Name"
                        rules="required"
                      >
                        <b-form-input
                          id="short-name"
                          v-model="row.item.shortName"
                          readonly
                          :state="errors.length > 0 ? false:null"
                          name="short-name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="row.item.name"
                          :state="errors.length > 0 ? false:null"
                          name="name"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <b-form-group
                      label="Phone"
                      label-for="phone"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Phone"
                        rules="required"
                      >
                        <b-form-input
                          id="phone"
                          v-model="row.item.phone"
                          :state="errors.length > 0 ? false:null"
                          name="phone"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <b-form-group
                      label="Fax"
                      label-for="fax"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Fax"
                      >
                        <b-form-input
                          id="fax"
                          v-model="row.item.fax"
                          :state="errors.length > 0 ? false:null"
                          name="fax"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <b-form-group
                      label="Website"
                      label-for="website"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Website"
                      >
                        <b-form-input
                          id="website"
                          v-model="row.item.website"
                          :state="errors.length > 0 ? false:null"
                          name="website"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <b-form-group
                      label="Address"
                      label-for="address"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address"
                      >
                        <b-form-input
                          id="address"
                          v-model="row.item.address"
                          :state="errors.length > 0 ? false:null"
                          name="address"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="invalid"
                      @click.prevent="validationForm(row.item)"
                    >
                      Update
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </template> -->
      </b-table>
    </b-card>
  </div>
</template>

<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BTable, BButton, BFormGroup, BFormInput,
  // BFormCheckbox, BForm, BCol, BRow,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCard, BTable, BButton, BFormGroup, BFormInput,
    // BFormCheckbox, BRow, BCol, ValidationProvider, ValidationObserver, BForm,
  },
  //  {
  //         label: 'Email',
  //         field: 'email',
  //         sortable: false,
  //       },
  data() {
    return {
      required,
      fields: ['id', 'shortName', 'name', 'phone', 'fax', 'website', 'address', 'created'],
      offices: [{
        id: 1,
        shortName: 'V004970',
        name: 'Royal LePage Sussex Klein Group',
        phone: '604-684-8844',
        fax: null,
        website: 'http://www.kleingroup.com',
        address: null,
        created: 'Mar 17, 2020',
      }],
      searchTerm: '',
    }
  },
  created() {
    this.$store.dispatch('superadmin/getOffices').then(response => {
      this.offices = response.data.map(r => {
        return {
          id: r.officeID,
          shortName: r.officeShortName,
          name: r.officeName,
          phone: r.officePhone,
          fax: r.officeFax,
          website: r.officeWebsite,
          address: r.officeAddress,
          created: new Date(r.created_at).toLocaleString('en-CA'),
        }
      })
    }).catch(err => {
      console.error(err)
    })
  },
  // methods: {
  //   validationForm(item) {
  //     console.log(item)
  //   },
  // },
}
</script>

<style>

</style>
